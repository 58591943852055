import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import CardPost from '../components/CardPost'

class BlogIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const posts = get(this, 'props.data.remark.posts')

    const cardPosts = []
    posts.forEach((data, i) => {
      cardPosts.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardPost data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
    })

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/logo.png`,
            },
          ]}
        />
        <div className="articles">
          <div className="container">
            <div className="row">
              <div className="col-3 text-right"><h1 className="underlined">Actus</h1></div>
              <div className="col-9"></div>
            </div>
            {cardPosts}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      filter: {
        frontmatter:{
          type: {eq : "blog"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            categories
            date(formatString: "DD.MM.YYYY")
            type
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
